import type { BrandImageVariantSize } from '@dx-ui/gql-types';

export type ImageVariant = {
  size: BrandImageVariantSize;
  url?: string | null;
};

export const isDev = process.env.NODE_ENV === 'development';

/**
 * For use in conjunction with [i18next context](https://www.i18next.com/translation-function/context)
 * Where UI copy varies by member tier using context, e.g.
 * ```
 * 'someText': 'Member tier text',
 * 'someText_silver': 'Silver tier text',
 * ...
 * ```
 * … to get the proper context value based on the tier key
 * @param tier
 */
export const getTierContextByLevel = (
  tier: 'B' | 'S' | 'G' | 'D' | 'L'
): 'silver' | 'gold' | 'diamond' | '' => {
  switch (tier) {
    case 'L':
    case 'D':
      return 'diamond';
    case 'G':
      return 'gold';
    case 'S':
      return 'silver';
    default:
      return '';
  }
};

/**
 *
 * @param placeId returned from Location input geocode call
 * @returns isolated and uppercased CTYHOCN value
 * @example parseCtyhocnFromPlaceId('dx-hotels::dalpagi') // returns 'DALPAGI'
 */
export const parseCtyhocnFromPlaceId = (placeId: string) =>
  placeId?.split(':')?.pop()?.toUpperCase();
